export default [
  {
    icon: {
      name: "heroicons-outline:chat-bubble-oval-left-ellipsis",
      class: "w-5 h-5",
    },
    activePath: "/tax-calculation",
    name: "Tax Calculation",
    roles: ["Super Admin", "Director", "Managing Director"],
    children: [
      {
        name: "Calculator PPH 21",
        to: "/tax-calculation/calculator-pph-21",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Excel Template",
        to: "/tax-calculation/excel-template",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Worksheet",
        to: "/tax-calculation/worksheet",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
    ],
  },
]
