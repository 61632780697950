export default [
  {
    icon: {
      name: "heroicons-outline:chat-bubble-oval-left-ellipsis",
      class: "w-5 h-5",
    },
    activePath: "/inapintar",
    name: "Inapintar",
    roles: ["Super Admin", "Director", "Managing Director"],
    children: [
      {
        name: "Chat Stream",
        to: "/inapintar/chat-stream",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Chat",
        to: "/inapintar/chat",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Chat Rag",
        to: "/inapintar/chat-rag",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Scoring Chat",
        to: "/inapintar/scoring-chat",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Master System Prompt",
        to: "/inapintar/master-system-prompt",
        icon: {
          name: "la:dot-circle",
          class: "w-4 h-4",
        },
      },
      {
        name: "Scoring Question",
        to: "/inapintar/scoring-question",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
      {
        name: "Score",
        to: "/inapintar/score",
        icon: { name: "la:dot-circle", class: "w-4 h-4" },
      },
    ],
  },
]
